import React, { Fragment, useState, useEffect } from 'react';
import { isAuthenticated } from '../../../Routes/ProtectedRoute';
import { OuterHeader, InnerHeader, HeaderNavWrapper, HeaderNavItem, HeaderNavLink, HeaderSecondaryLink, HeaderCTAWrapper, SideNavToggler, HeaderLogoWrapper } from './styles/index';
import { SiteFormLogo } from '../SiteLogo';
import HeaderDropdown from './HeaderDropdown';
import HomeSideNav from '../SideNav';
import SiteButton from '../FormComponents/SiteButton';
import { HiOutlineBars3 } from 'react-icons/hi2';
import { VscClose } from 'react-icons/vsc';

export default function Header({ pageType, activeBorder, customPricingLink }) {
  const [scrolling, setScrolling] = useState(false);
  const [mobileMenu, openMobileMenu] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    let top = window.scrollY <= 0;
    setScrolling(true);
    if (top === true) {
      setScrolling(false);
    }
  };

  return (
    <Fragment>
      <OuterHeader activeBorder={activeBorder} headerScrolling={scrolling} mobileMenu={mobileMenu}>
        <InnerHeader headerScrolling={scrolling}>
          <div className="w-100 h-100 d-flex align-items-center justify-content-between">
            <div className="h-100 d-flex align-items-center">
              <a href={isAuthenticated() ? '/dashboard' : '/'}>
                <HeaderLogoWrapper>
                  <SiteFormLogo />
                </HeaderLogoWrapper>
              </a>

              <HeaderNavWrapper>
                <HeaderDropdown productsDropdown />
                {/*<HeaderNavItem>Solutions</HeaderNavItem>*/}
                {customPricingLink ? <HeaderNavLink href={customPricingLink}>Pricing</HeaderNavLink> : null}
              </HeaderNavWrapper>
            </div>

            <div className="d-flex align-items-center">
              <HeaderCTAWrapper>
                {!isAuthenticated() ? (
                  <Fragment>
                    <HeaderSecondaryLink href="/signin">Sign in</HeaderSecondaryLink>
                    <SiteButton headerBtn style={{ marginLeft: '1.5rem' }} onClick={() => (window.location.href = '/signup')}>
                      Try for free
                    </SiteButton>
                  </Fragment>
                ) : null}
              </HeaderCTAWrapper>
              <div className="d-lg-none ms-4">
                <SideNavToggler onClick={() => openMobileMenu(!mobileMenu)}>{mobileMenu ? <VscClose /> : <HiOutlineBars3 />}</SideNavToggler>
              </div>
            </div>
          </div>
        </InnerHeader>
      </OuterHeader>

      {/* mobile side navigation */}
      <HomeSideNav show={mobileMenu} closeSideMenu={() => openMobileMenu(false)} pricingLink={customPricingLink} />
    </Fragment>
  );
}

import React, { Fragment } from 'react';
import styled from 'styled-components';
import { HiOutlineArrowRight } from 'react-icons/hi';

export const RightArrow = styled(HiOutlineArrowRight)`
  position: relative;
  transition: var(--hoverTransitionDuration) transform ease-in-out;
  margin-left: 6px;
`;

export const SiteBtn = styled.button.attrs({ className: 'siteBtn' })`
  border: none;
  outline: none;
  padding: 8px;
  height: 45px;
  min-width: 95px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 15px;
  font-family: var(--commonFontMedium);
  background: var(--bgBrandColor);
  border-radius: var(--buttonRadius);
  transition: var(--hoverTransitionDuration) ease-in-out;
  &:hover {
    filter: brightness(94%);
    outline: none;
  }
  &:hover ${RightArrow} {
    transform: translateX(4px);
  }
  &:active {
    outline: none;
  }
  &:disabled {
    background: var(--bs-gray-500);
    cursor: not-allowed;
  }
`;

export const BorderBtn = styled(SiteBtn).attrs({ className: 'borderBtn' })`
  color: var(--siteBrandColor);
  background: #fff;
  border: 2px solid var(--siteBrandColor);
  &:hover {
    filter: brightness(97%);
  }
  &:disabled {
    color: var(--greyColor);
    background: #fff;
    border-color: var(--bs-gray-500);
  }
`;

export const SecondaryBtn = styled(SiteBtn).attrs({ className: 'borderBtn' })`
  color: var(--siteBrandColor);
  background: var(--siteBrandSecondary);
`;

export const BannerBtn = styled(SiteBtn).attrs({ className: 'bannerBtn' })`
  width: 100%;
  max-width: 320px;
  height: 54px;
  padding: 8px;
  font-size: 16px;
`;

export const HeaderBtn = styled(SiteBtn).attrs({ className: 'headerBtn' })`
  height: 40px;
  min-width: 130px;
  @media (min-width: 992px) {
    min-width: 135px;
  }
`;

export const FormBtn = styled(SiteBtn).attrs({ className: 'formBtn' })`
  width: 100%;
`;

export const PricingBtn = styled(FormBtn).attrs({ className: 'pricingBtn' })`
  height: 45px;
  color: ${({ bordered }) => bordered === 'true' && 'var(--siteBrandColor)'};
  background: ${({ bordered }) => (bordered === 'true' ? 'transparent' : 'var(--bgBrandColor)')};
  border: ${({ bordered }) => bordered === 'true' && '1px solid var(--borderColor)'};
  &:hover {
    border: ${({ bordered }) => bordered === 'true' && '1px solid var(--bgBrandColor)'};
  }
`;

export const SpinnerBtn = styled(SiteBtn).attrs({ className: 'spinnerBtn' })`
  width: 100%;
  height: 50px;
  padding: 4px 8px;
`;

export const DboardBtn = styled(SpinnerBtn).attrs({ className: 'dboardBtn' })`
  height: 45px;
  max-width: 200px;
`;

const BtnLoadingCircle = styled.span`
  display: inline-block;
  width: 18px;
  height: 18px;
  border: 3px solid var(--bs-light);
  border-radius: 50%;
  border-top-color: transparent;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
  @keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
`;

export default function SiteButton({
  onClick,
  style,
  children,
  active,
  disabled,
  loadingText,
  id,
  ref,
  href,
  commonBtn,
  secondaryBtn,
  commonBorderBtn,
  bannerBtn,
  headerBtn,
  formBtn,
  spinnerBtn,
  dboardBtn,
  pricingBtn,
  bordered,
}) {
  return (
    <Fragment>
      {commonBtn && (
        <SiteBtn id={id} onClick={onClick} style={style} disabled={disabled}>
          <span>{children}</span>
        </SiteBtn>
      )}

      {secondaryBtn && (
        <SecondaryBtn id={id} onClick={onClick} style={style} disabled={disabled}>
          <span>{children}</span>
        </SecondaryBtn>
      )}

      {commonBorderBtn && (
        <BorderBtn id={id} onClick={onClick} style={style} disabled={disabled}>
          <span>{children}</span>
        </BorderBtn>
      )}

      {bannerBtn && (
        <BannerBtn id={id} onClick={onClick} style={style} disabled={disabled}>
          <span>{children}</span>
          <RightArrow />
        </BannerBtn>
      )}

      {headerBtn && (
        <HeaderBtn id={id} onClick={onClick} style={style} disabled={disabled}>
          <span>{children}</span>
          <RightArrow />
        </HeaderBtn>
      )}

      {formBtn && (
        <FormBtn id={id} onClick={onClick} style={style} active={active} disabled={disabled}>
          <span>{children}</span>
          <RightArrow />
        </FormBtn>
      )}

      {pricingBtn && (
        <PricingBtn id={id} onClick={onClick} style={style} active={active} disabled={disabled} bordered={bordered}>
          <span>{children}</span>
          <RightArrow />
        </PricingBtn>
      )}

      {spinnerBtn && (
        <SpinnerBtn id={id} onClick={onClick} style={style} active={active} disabled={disabled} loadingText={loadingText} ref={ref}>
          {active ? (
            <Fragment>
              <span className="pe-2">{loadingText ? loadingText : children}</span>
              <BtnLoadingCircle />
            </Fragment>
          ) : (
            <Fragment>
              <span>{children}</span> <RightArrow />
            </Fragment>
          )}
        </SpinnerBtn>
      )}

      {dboardBtn && (
        <DboardBtn id={id} onClick={onClick} style={style} active={active} disabled={disabled} loadingText={loadingText} ref={ref}>
          {active ? (
            <Fragment>
              <span className="pe-2">{loadingText ? loadingText : children}</span>
              <BtnLoadingCircle />
            </Fragment>
          ) : (
            <Fragment>
              <span>{children}</span>
            </Fragment>
          )}
        </DboardBtn>
      )}
    </Fragment>
  );
}

import React, { Fragment } from 'react';
import { Accordion } from 'react-accessible-accordion';
import { isAuthenticated } from '../../../Routes/ProtectedRoute';
import productsData from '../../../Data/productsData';
import { HeaderSecondaryLink } from '../Header/styles';
import { HomeSideMenuContainer, SideMenuOverlay, HomeSideMenuWrapper, SideNavInnerContainer, SideNavLink, SideNavDiv } from './styles/HomeSideNav';
import { DropdownNavIcon, DropdownNavText, DropdownNavDescText } from '../Header/HeaderDropdown/styles';
import SideNavAccordian from '../Header/styles/SideNavAccordians';
import SiteButton from '../FormComponents/SiteButton';
import { MdOutlineArrowOutward } from 'react-icons/md';

export default function HomeSideNav({ show, closeSideMenu, pricingLink }) {
  return (
    <HomeSideMenuContainer show={show}>
      <SideMenuOverlay show={show} onClick={closeSideMenu} />
      <HomeSideMenuWrapper show={show}>
        <Accordion preExpanded={['products-nav']} allowZeroExpanded={true} allowMultipleExpanded={false}>
          <SideNavAccordian groudId="products-nav" groupName="Products">
            <div className="py-2">
              {productsData.map(({ productID, productIcon, productDisplayName, productPageLink, productDesc }) => (
                <SideNavDiv key={productID} href={productPageLink}>
                  <DropdownNavIcon src={productIcon} alt="" />
                  <div>
                    <DropdownNavText>{productDisplayName}</DropdownNavText>
                    <DropdownNavDescText>{productDesc}</DropdownNavDescText>
                  </div>
                </SideNavDiv>
              ))}
            </div>
          </SideNavAccordian>
        </Accordion>

        <SideNavLink href={pricingLink}>
          <p className="me-2">Pricing</p> <MdOutlineArrowOutward className="fs-18" />
        </SideNavLink>
        <hr className="mt-0 mb-5" />
        <div className="hideOnLargeMobile d-flex align-items-center justify-content-center px-2">
          {!isAuthenticated() ? (
            <Fragment>
              <HeaderSecondaryLink href="/signin">Sign in</HeaderSecondaryLink>
              <SiteButton headerBtn style={{ marginLeft: '2.5rem' }} onClick={() => (window.location.href = '/signup')}>
                Try for free
              </SiteButton>
            </Fragment>
          ) : null}
        </div>
      </HomeSideMenuWrapper>
    </HomeSideMenuContainer>
  );
}

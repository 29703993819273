import Axios from 'axios';

const userLocation = async () => {
  const apiConfigs = [
    { url: 'https://ipinfo.io/json?', key: '92a68ebdcb989b' },
    { url: 'https://ipinfo.io/json?', key: 'e99a59ce05dc82' },
  ];

  for (const { url, key } of apiConfigs) {
    try {
      const apiResult = await Axios.get(`${url}token=${key}`);
      return apiResult.data;
    } catch (error) {
      console.error('error fetching location');
    }
  }

  console.error('Failed fetch location');
  return {};
};

export default userLocation;

export const getCountryCode = async (userLocationData, setUserLocationDetails) => {
  let countryCode;

  try {
    if (userLocationData && userLocationData.country) {
      countryCode = userLocationData.country;
    } else {
      let host = window.location.hostname;
      if (host.includes('leyaa.ai')) {
        let locationDetails = await userLocation();
        countryCode = locationDetails.country;
        if (countryCode) {
          setUserLocationDetails({ ...locationDetails, from: 'locationApi' });
        }
      } else {
        setUserLocationDetails({ postal: '600032', city: 'California', country: 'US', from: 'locationApi', countryName: 'America' });
        countryCode = 'US';
      }
    }
  } catch (err) {
    if (err) {
      setUserLocationDetails({ postal: '600032', city: 'defaultUS', country: 'US', from: 'locationApi', countryName: 'America' });
      countryCode = 'US';
    }
  }

  return countryCode;
};

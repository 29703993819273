import styled from 'styled-components';
import { RiArrowDownSLine } from 'react-icons/ri';

// large courses start
export const HeaderDropdownContainer = styled.div`
  display: none;
  :focus {
    outline: none;
  }
  @media (min-width: 992px) {
    position: relative;
    display: inline-block;
  }
`;

export const HeaderDropdownIcon = styled(RiArrowDownSLine)`
  font-size: 16px;
  margin-left: 2px;
  transform: ${({ active }) => active && 'rotate(180deg)'};
  transition: 0.1s;
`;

export const DropdownOuterWrapper = styled.div`
  display: none;
  @media (min-width: 992px) {
    display: ${({ active }) => active && 'block'};
    position: absolute;
    top: 60px;
    left: 0px;
    min-width: 280px;
  }
`;

export const DropdownTitleWrapper = styled.div`
  text-align: center;
  color: white;
  background: linear-gradient(to right, var(--bgBrandColor), #7465ff, #836fff, #9079ff, var(--bgBrandLightColor));
`;

export const DropdownNavsWrapper = styled.div`
  padding: 12px 0px;
  background-color: white;
  border-radius: var(--cardRadius);
  box-shadow: 0px 4px 12px var(--greyBorderColor);
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: block;
  }
`;

export const DropdownNavDiv = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 24px;
  cursor: pointer;
  background-color: white;
  &:hover {
    background-color: var(--dboardPrimaryBgColor);
  }
  &:hover p {
    color: var(--siteBrandColor);
  }
`;

export const DropdownNavIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 16px;
`;

export const DropdownNavText = styled.p.attrs({ className: 'home-nav-title' })`
  color: var(--sitePrimaryColor);
  font-family: var(--commonFontMedium);
  font-size: 15px;
  line-height: normal;
`;

export const DropdownNavDescText = styled.span`
  color: var(--siteDescriptionColor);
  font-size: 13px;
`;

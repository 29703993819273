import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { isAuthenticated } from '../../Routes/ProtectedRoute';
import { getCountryCode } from '../../Utils/userLocation';
import { updateUserLocationDetails } from '../../Actions';
import Banner from '../UI/Banner';
import Header from '../UI/Header';
// import ProductSection from '../UI/ProductSection';
import Footer from '../UI/Footer';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countryCode: '',
    };
  }

  async componentDidMount() {
    const { userLocationDetails, updateUserLocationDetails } = this.props;
    if (isAuthenticated()) {
      this.signedRedirection();
    } else {
      let countryCode = await getCountryCode(userLocationDetails, updateUserLocationDetails);
      this.setState({ countryCode: countryCode });
    }
  }

  signedRedirection = () => (window.location.href = '/dashboard');

  render() {
    const { countryCode } = this.state;

    return (
      <Fragment>
        <Header />
        <Banner />
        {/*<ProductSection secondarySection={true} />*/}
        <Footer />
      </Fragment>
    );
  }
}

const mapStateToProps = ({ User }) => ({
  userLocationDetails: User.locationDetails,
});

const mapDispatchToProps = (dispatch) => ({
  updateUserLocationDetails: (locationDetails) => dispatch(updateUserLocationDetails(locationDetails)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);

const cognitoProvider = 'CognitoIdentityServiceProvider';
const userPoolClientId = 'ejjcv48ddi95t4p3hljj6r7po';

export const getCookieUserName = () => {
  const cookieName = `${cognitoProvider}.${userPoolClientId}.LastAuthUser`;
  const cookieValue = document.cookie.match('(^|;)\\s*' + cookieName + '\\s*=\\s*([^;]+)');
  const cookieResult = cookieValue ? cookieValue.pop() : null;
  // console.log('cookieResult: ', cookieResult ? cookieResult : 'sorry try again');
  return `${cognitoProvider}.${userPoolClientId}.${cookieResult}`;
};

export const getCookieAccessToken = () => {
  const cookiePrefix = getCookieUserName();
  const cookieName = `${cookiePrefix}.accessToken`;
  const cookieValue = document.cookie.match('(^|;)\\s*' + cookieName + '\\s*=\\s*([^;]+)');

  const cookieResult = cookieValue ? cookieValue.pop() : null;
  // console.log('cookieResult: ', cookieResult ? cookieResult : 'sorry try again');
  return cookieResult;
};

export const getCookieIdToken = () => {
  const cookiePrefix = getCookieUserName();
  const cookieName = `${cookiePrefix}.idToken`;
  const cookieValue = document.cookie.match('(^|;)\\s*' + cookieName + '\\s*=\\s*([^;]+)');

  const cookieResult = cookieValue ? cookieValue.pop() : null;
  // console.log('cookieResult: ', cookieResult ? cookieResult : 'sorry try again');
  return cookieResult;
};

export const getCookieRefreshToken = () => {
  const cookiePrefix = getCookieUserName();
  const cookieName = `${cookiePrefix}.refreshToken`;
  const cookieValue = document.cookie.match('(^|;)\\s*' + cookieName + '\\s*=\\s*([^;]+)');

  const cookieResult = cookieValue ? cookieValue.pop() : null;
  // console.log('cookieResult: ', cookieResult ? cookieResult : 'sorry try again');
  return cookieResult;
};

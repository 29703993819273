import { Amplify } from 'aws-amplify';
import { CookieStorage } from 'aws-amplify/utils';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';

const amplifyConfig = () => {
  Amplify.configure({
    Auth: {
      Cognito: {
        userPoolId: 'us-east-1_Iuah1zX6s',
        userPoolClientId: 'ejjcv48ddi95t4p3hljj6r7po',
        identityPoolId: 'us-east-1:17398512-17ea-40da-bd42-9bd351930616',
        loginWith: {
          // OPTIONAL - Hosted UI configuration
          oauth: {
            domain: 'auth.leyaa.ai',
            scopes: ['email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
            redirectSignIn: ['http://localhost:3000/signin', 'https://leyaa.ai/signin', 'http://localhost:3000/signup', 'https://leyaa.ai/signup'],
            redirectSignOut: ['http://localhost:3000/signin', 'https://leyaa.ai/signin'],
            responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
          },
        },
      },
    },
  });
  cognitoUserPoolsTokenProvider.setKeyValueStorage(new CookieStorage());
};

// You can get the current config object
export default amplifyConfig;

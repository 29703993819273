import React, { useEffect } from 'react';
import Lottie from 'lottie-web-light';
import lottieJsonOptions from '../../../Data/lottieJsonOptions';
// import reactLogo from '../../../Animations/home-banner-white.json';
import testverseBannerSvg from '../../../Animations/testverse-banner-05-sept-2024-02-47-compressed.json';
import { HomeBannerOuter, HomeBannerInner, HomePrimaryContent, HomeLogo, HomeHeading, HomeSecondaryContent, BannerDescriptionText, PackagesVideoWrapper } from './styles';
import SiteButton from '../FormComponents/SiteButton';

function Banner() {
  useEffect(() => {
    let bannerSvgDiv = document.querySelector('#banner-svg');
    if (bannerSvgDiv && bannerSvgDiv.innerHTML === '') {
      Lottie.loadAnimation({
        container: bannerSvgDiv,
        animationData: testverseBannerSvg,
        ...lottieJsonOptions,
      });
    }
  }, []);

  return (
    <HomeBannerOuter>
      <HomeBannerInner>
        <HomePrimaryContent>
          {/*<HomeLogo src="https://leyaa.ai/Assets/leyaa-lts-medium.svg" alt="" />*/}
          <HomeHeading>
            The intuitive AI platform to supercharge your <span className="blueGradientTxt">learning outcomes.</span>
          </HomeHeading>

          <BannerDescriptionText>
            Supercharge your learning experience with our AI-powered platform. The one-stop solution to make learning truly personalized.
          </BannerDescriptionText>

          <SiteButton bannerBtn onClick={() => (window.location.href = '/signup')} style={{ margin: '0px auto' }}>
            Start now
          </SiteButton>
        </HomePrimaryContent>

        <HomeSecondaryContent>
          <PackagesVideoWrapper id="banner-svg" />
        </HomeSecondaryContent>
      </HomeBannerInner>
    </HomeBannerOuter>
  );
}

export default Banner;

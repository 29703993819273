import styled from 'styled-components';

export const SiteWrapper = styled.main.attrs({ id: 'site-wrapper' })`
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  color: var(--siteDescriptionColor);
  background: #fff;
`;

export const SectionOuter = styled.section`
  display: flex;
  flex-grow: 1;
  padding: 40px 0px;
  background-color: ${({ secondarySection }) => secondarySection && 'var(--bgPrimaryColor)'};
`;

export const SectionInner = styled.div`
  width: 100%;
  max-width: var(--layoutMaxWidth);
  padding: 0px var(--siteXAxisPadd);
  margin: 0px auto;
  @media (min-width: 992px) {
    width: 95%;
  }
`;

export const SectionHeading = styled.h2`
  font-size: 24px;
  font-family: var(--headingFontSemiBold);
  text-align: center;
  padding-bottom: 32px;
  @media (min-width: 481px) {
    font-size: 28px;
  }
  @media (min-width: 576px) {
    font-size: 32px;
  }
  @media (min-width: 992px) {
    font-size: 34px;
    padding-bottom: 48px;
  }
  @media (min-width: 1200px) {
    font-size: 36px;
  }
  @media (min-width: 1400px) {
    font-size: 40px;
  }
  @media (min-width: 1800px) {
    font-size: 42px;
  }
`;

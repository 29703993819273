import styled from 'styled-components';

export const HomeSideMenuContainer = styled.nav.attrs({ id: 'home-side-nav' })`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  transition: var(--layoutTransitionDuration);
  z-index: ${({ show }) => (show ? '100' : '-10')};
  @media (min-width: 992px) {
    display: none;
  }
`;

export const SideMenuOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  inset: 0px;
  transition: var(--layoutTransitionDuration);
  z-index: ${({ show }) => (show ? '0' : '-10')};
  background: ${({ show }) => (show ? 'var(--greyOverlay)' : 'transparent')};
  @media (min-width: 992px) {
    display: none;
  }
`;

export const HomeSideMenuWrapper = styled.div`
  position: absolute;
  width: 100%;
  max-width: var(--sideNavWidth);
  height: 100%;
  padding: calc(var(--headerHeight) + 8px) 8px 40px;
  transition: var(--layoutTransitionDuration);
  right: ${({ show }) => (show ? '0%' : '-320px')};
  z-index: 1;
  background-color: #fff;
  overflow-y: auto;
  box-shadow: 0px 0px 2px var(--greyOverlay);
  @media (min-width: 992px) {
    display: none;
  }
  &::-webkit-scrollbar {
    display: block;
  }
`;

export const SideNavInnerContainer = styled.div`
  padding: 16px 8px 48px;
  @media (min-width: 481px) {
    padding: 16px 16px 48px;
  }
`;

export const SideNavLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: var(--navFontSize);
  font-family: var(--commonFontMedium);
  color: var(--sitePrimaryColor);
  padding: var(--sideNavPadd);
  &:hover {
    color: var(--siteBrandColor);
  }
`;

export const SideNavDiv = styled.a`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  border-radius: var(--buttonRadius);
  cursor: pointer;
  background-color: white;
  &:hover {
    background-color: var(--dboardPrimaryBgColor);
  }
  &:hover .home-nav-title {
    color: var(--siteBrandColor);
  }
`;

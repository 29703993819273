import styled from 'styled-components';

export const HomeBannerOuter = styled.section`
  position: relative;
  display: flex;
  flex-grow: 1;
  padding: 60px 0px 40px;
  &:after {
    position: absolute;
    content: '';
    width: 100%;
    height: 30%;
    bottom: 0;
    background: var(--dboardPrimaryBgColor);
  }
  @media (min-width: 481px) {
    &:after {
      height: 35%;
    }
  }
  @media (min-width: 768px) {
    &:after {
      height: 40%;
    }
  }
  @media (min-width: 1400px) {
    &:after {
      height: 45%;
    }
  }
  @media (min-width: 1800px) {
    padding-top: 80px;
  }
`;

// export const BannerBgDiv = styled.div`
//   position: absolute;
// `;

export const HomeBannerInner = styled.div`
  width: 100%;
  max-width: var(--layoutMaxWidth);
  padding: 0px var(--siteXAxisPadd);
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 1;
  @media (min-width: 992px) {
    width: 95%;
  }
`;

export const HomePrimaryContent = styled.div`
  max-width: 750px;
  margin: 0px auto 42px;
  text-align: center;
  @media (min-width: 992px) {
    max-width: 800px;
  }
  @media (min-width: 1200px) {
    max-width: 900px;
  }
  @media (min-width: 1400px) {
    max-width: 980px;
  }
  @media (min-width: 1800px) {
    max-width: 1050px;
  }
`;

export const HomeLogo = styled.img`
  width: 210px;
  margin-bottom: 8px;
  @media (min-width: 576px) {
    width: 230px;
  }
  @media (min-width: 992px) {
    width: 250px;
  }
  @media (min-width: 1200px) {
    width: 280px;
  }
  @media (min-width: 1400px) {
    width: 300px;
  }
`;

export const HomeHeading = styled.h1`
  font-size: 30px;
  // font-family: var(--headingFontBold);
  padding-bottom: 24px;
  line-height: 1.125;
  @media (min-width: 481px) {
    font-size: 34px;
  }
  @media (min-width: 576px) {
    font-size: 38px;
  }
  @media (min-width: 992px) {
    font-size: 42px;
  }
  @media (min-width: 1200px) {
    font-size: 46px;
  }
  @media (min-width: 1400px) {
    font-size: 50px;
  }
  @media (min-width: 1800px) {
    font-size: 54px;
  }
`;

export const HomeSecondaryContent = styled.div`
  max-width: 750px;
  margin: 0px auto;
  text-align: center;
  @media (min-width: 992px) {
    max-width: 800px;
  }
  @media (min-width: 1200px) {
    max-width: 900px;
  }
  @media (min-width: 1400px) {
    max-width: 980px;
  }
  @media (min-width: 1800px) {
    max-width: 1050px;
  }
`;

export const PackagesBannerInner = styled.div`
  width: 100%;
  max-width: var(--layoutMaxWidth);
  padding: 0px var(--siteXAxisPadd);
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 1;
  @media (min-width: 992px) {
    width: 95%;
  }
`;

export const PackagesPrimaryContent = styled.div`
  max-width: 750px;
  margin: 0px auto 42px;
  text-align: center;
  @media (min-width: 992px) {
    max-width: 800px;
  }
  @media (min-width: 1200px) {
    max-width: 900px;
  }
  @media (min-width: 1400px) {
    max-width: 980px;
  }
  @media (min-width: 1800px) {
    max-width: 1050px;
  }
`;

export const PackagesIcon = styled.img`
  width: 22px;
  margin-right: 4px;
  margin-bottom: 4px;
`;

export const PackagesTitle = styled.p`
  font-size: 13px;
  font-family: var(--commonFontMedium);
  color: var(--sitePrimaryColor);
  line-height: normal;
  letter-spacing: 0.25px;
  text-transform: uppercase;
  padding-bottom: 16px;
  @media (min-width: 768px) {
    font-size: 14px;
  }
  @media (min-width: 1400px) {
    font-size: 15px;
  }
`;

export const PackagesHeading = styled.h1`
  font-size: 30px;
  // font-family: var(--headingFontBold);
  padding-bottom: 24px;
  line-height: 1.125;
  @media (min-width: 481px) {
    font-size: 34px;
  }
  @media (min-width: 576px) {
    font-size: 38px;
  }
  @media (min-width: 992px) {
    font-size: 42px;
  }
  @media (min-width: 1200px) {
    font-size: 46px;
  }
  @media (min-width: 1400px) {
    font-size: 50px;
  }
  @media (min-width: 1800px) {
    font-size: 54px;
  }
`;

export const PackagesSecondaryContent = styled.div`
  max-width: 750px;
  margin: 0px auto;
  text-align: center;
  @media (min-width: 992px) {
    max-width: 800px;
  }
  @media (min-width: 1200px) {
    max-width: 900px;
  }
  @media (min-width: 1400px) {
    max-width: 980px;
  }
  @media (min-width: 1800px) {
    max-width: 1050px;
  }
`;

export const PackagesVideoWrapper = styled.div`
  width: 100%;
  min-height: 160px;
  // overflow: hidden;
  // border-radius: 8px;
  // box-shadow: 0px 3px 8px 4px var(--greyShadowColor);
  @media (min-width: 576px) {
    min-height: 250px;
  }
  @media (min-width: 992px) {
    min-height: 300px;
  }
`;

export const BannerDescriptionText = styled.p`
  font-size: 15px;
  font-family: var(--commonFontMedium);
  color: var(--siteDescriptionColor);
  padding-bottom: 28px;
  @media (min-width: 768px) {
    font-size: 16px;
  }
  @media (min-width: 1400px) {
    font-size: 17px;
  }
`;
